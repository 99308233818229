<template>
  <el-drawer :size="width" v-model="showDialog" :before-close="handleClose" :direction="direction"
    class="customer-drawer">
    <div class="left-block" v-if="isRightOperation">
      <el-button type="primary" size="small" @click="addCollection">回款登记</el-button>
      <el-button type="primary" size="small" @click="addSign">签单登记</el-button>
    </div>
    <div class="right-block" v-if="!isHiddenOperation">
      <el-button type="primary" size="small" @click="handleImport">移入重要客户</el-button>
      <el-button type="primary" size="small" @click="handlePublicPool">移入公海</el-button>
      <el-button type="primary" size="small" @click="handleRubbish">放入垃圾库</el-button>

      <el-button type="primary" size="small" @click="handlePassage">移入在途客户</el-button>

      <el-button type="primary" size="small" @click="handlePrevCustomer" v-if="isRightOperation">上一个</el-button>
      <el-button type="primary" size="small" @click="handleNextCustomer" v-if="isRightOperation">下一个</el-button>
    </div>
    <el-tabs class="tabs-block" v-model="activeName">
      <el-tab-pane label="客户详情" name="1">
        <FormCustomer :forms="forms" :formData="formData" />
        <div class="content-customer">
          <div class="left fast-write">
            <h2>快捷录入</h2>
            <FormCustomer :forms="fastForms" :formData="formData" :rules="rules" />
            <el-button v-if="!isShowSaveBtn" type="primary" size="small" class="submit-btn" @click="handleSubmit">保存
            </el-button>
            <h2 v-if="!isHiddenTX">
              <p>提醒事项</p>
              <el-button type="text" @click="addRemind">添加</el-button>
            </h2>
            <el-row>
              <el-col :span="24" class="remind-block" v-for="(item, index) in reminds" :key="index">
                <el-date-picker v-model="item.remindTime" type="datetime" size="small" placeholder="选择日期时间"
                  value-format="YYYY-MM-DD HH:mm:ss">
                </el-date-picker>
                <el-input class="content-block" v-model="item.content" size="small" placeholder="请输入内容" clearable>
                </el-input>
                <!-- <el-radio-group
                  size="small"
                  v-model="item.status"
                >
                  <el-radio-button
                    v-for="(op, ind) in remindOptions"
                    :label="op.val"
                    :key="ind"
                  >{{op.label}}</el-radio-button>

                </el-radio-group> -->
                <el-button type="text" @click="handleSaveRemind(index, item.id)">保存</el-button>
                <el-button type="text" @click="handleDeleteRemind(index, item.id)">删除</el-button>
              </el-col>
            </el-row>
          </div>
          <div class="right">
            <el-tabs class="tabs-block" v-model="followActiveName">
              <el-tab-pane label="跟进记录备注" name="1">
                <ul class="remark-block">
                  <li v-for="(item, index) in followList" :key="index">
                    <div class="avatar-header" v-if="item.type == 1">
                      <div class="avatar">{{ item.username.trim().substring(0, 1) }}</div>
                      <div>
                        <div class="header">
                          <div class="title">{{ item.username }}</div>
                          <div class="time">{{ item.createTime }}</div>
                          <div class="comment " v-if="userInfo.roleId != 16"><span class="name-block"
                              @click="showComment(item)">评</span></div>
                        </div>
                      </div>
                    </div>
                    <div class="content" v-if="item.type == 1">
                      <div class="line-left" v-if="index != followList.length - 1">|</div>
                      <div class="line-left" v-else></div>
                      <div>
                        <div>{{ item.message }}</div>
                        <div v-if="commentListData.length > 0">
                          <!-- 评论列表 -->
                          <div class="comments" 
                            v-for="(subitem, subindex) in commentListData.filter(m => m.followRecordId == item.id && m.parentId == 0)"
                            :key="subindex">
                            <div >
                            <span v-if="subitem.state == 0" class="user cursor"
                              @click="handleReply(subitem, item)">{{
                                  subitem.commentUserName
                              }}：</span>

                            <span v-else class="user" >{{
                                subitem.commentUserName
                            }}：</span>
                            <span class="msg">{{ subitem.content }}</span>
                          </div>
                            <!-- 回复 -->
                            <div style="margin-left:0px"
                              v-for="(replyitem, replyindex) in commentListData.filter(m => m.parentId == subitem.id)"
                              :key="replyindex">
                              <div >
                                <span v-if="replyitem.state == 0" class="user cursor" @click="handleReply(replyitem, item)">{{
                                    replyitem.commentUserName
                                }}：</span>
                                <span  v-else class="user">{{
                                    replyitem.commentUserName
                                }}：</span>
                                <span class="reply">@{{ subitem.commentUserName }} // </span>
                                <span class="msg">{{ replyitem.content }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <audio v-if="item.voicePath" :src="item.voicePath" controls="controls">
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </el-tab-pane>

              <el-tab-pane label="分配记录备注" name="2">
                <ul class="remark-block">
                  <li v-for="(item, index) in followList" :key="index">
                    <div class="avatar-header" v-if="item.type == 0">
                      <div class="avatar">{{ item.username }}</div>
                      <div>
                        <div class="header">
                          <div class="title">{{ item.username }}</div>
                          <div class="time">{{ item.createTime }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="content" v-if="item.type == 0">
                      <div class="line-left" v-if="index != followList.length - 1">|</div>
                      <div class="line-left" v-else></div>
                      <div>
                        <div>{{ item.message }}</div>
                        <div>
                          <audio v-if="item.voicePath" :src="item.voicePath" controls="controls">
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </el-tab-pane>

            </el-tabs>


            <!--            <el-button-->
            <!--              type="primary"-->
            <!--              size="small"-->
            <!--              class="submit-btn"-->
            <!--              @click="handleSubmit"-->
            <!--              >保存</el-button-->
            <!--            >-->
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-drawer>
  <CollectionDialog title="回款登记" :isShowDialog="isShowCollection" :forms="collectionForms"
    :formData="collectionFormData" :width="600" @submit="handleCollectionSubmit" @close="handleCollectionClose" />
  <CollectionDialog title="签单登记" :isShowDialog="isShowSign" :forms="signForms" :formData="signFormData" :width="600"
    @submit="handleSignSubmit" @close="handleSignClose" />

  <el-dialog :title=commentDialogTitle width="30%" v-model="isShowComment">
    <el-row>
      <el-col :span="commentListData.length > 0 ? 24 : 24">
        <el-form :model="commentFormData" :rules="commentRules" class="form-block" :label-position="top"
          ref="commentFormRef">
          <el-form-item>
            <el-row style="border:1px solid #409eff">
              <el-col :span="1">
                <span style="writing-mode:tb-rl" class="gjjl">跟进记录</span>
              </el-col>
              <el-col :span="23" style="padding-left:10px">
                <p>{{ currentSelectedRow.value.username }}<span style="float: right;padding-right: 10px;">{{
                    currentSelectedRow.value.createTime
                }}</span></p>
                <p>{{ currentSelectedRow.value.message }}</p>
              </el-col>
            </el-row>
            <el-row style="border:1px solid #409eff;margin-top: 10px;" v-if="currentReplyComment.value">
              <el-col :span="1">
                <span style="writing-mode:tb-rl" class="gjjl">评论内容</span>
              </el-col>
              <el-col :span="23" style="padding-left:10px">
                <p>{{ currentReplyComment.value.commentUserName }}<span style="float: right;padding-right: 10px;">{{
                    currentReplyComment.value.createTime
                }}</span></p>
                <p>{{ currentReplyComment.value.content }}</p>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item prop="content">
            <el-input type="textarea" v-model="commentFormData.content" :rows="10" placeholder="请输入内容..."></el-input>
          </el-form-item>
        </el-form>
      </el-col>
      <!-- <el-col :span="12" v-if="commentListData.length > 0">
        <el-timeline style="margin-left:10px;margin-left: 10px;overflow-y: scroll;height: 400px;">
          <el-timeline-item v-for="(item, index) in commentListData" :key="index" placement="top">
            <el-card>
              <p>{{ item.commentUserName }} {{ item.createTime }}</p>
              <h4>{{ item.content }}</h4>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </el-col> -->
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCommonColse" size="small">取消</el-button>
        <el-button type="primary" @click="submitCommentForm" size="small">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {
  ElForm,
  ElFormItem,
  ElButton,
  ElDatePicker,
  ElDrawer,
  ElMessage,
  ElRow,
  ElTabPane,
  ElTabs,
  ElCol,
  ElInput,
  ElRadioGroup,
  ElRadioButton,
  ElMessageBox,
  ElDialog,
  ElTimeline,
  ElTimelineItem,
  ElCard
} from "element-plus";
import { computed, reactive, ref, watch } from "vue";
import FormCustomer from "@/components/form";
import { forms, radioForms, collectionForms, signForms } from "./index.js";
import {
  getDictionaries,
  postRemind,
  putRemind,
  deleteRemind,
  postCollection,
  postSign,
} from "@/api/method/common";
import {
  putCustomer,
  getCustomer,
  movetoImportant,
  movetoPublicPool, movetoRubbish, movetoPassage,checkCurrUserIsFollower
} from "@/api/method/customer";
import { addFollowRecordCommon, followRecordCommentListByCustomer } from "@/api/method/call-record";
import { reset } from "@/until";
import CollectionDialog from "@/components/dialog-form";
import { useStore } from "vuex";

export default {
  name: "DrawerForm",
  props: ["isShowDialog", "isShowSaveBtn", "isHiddenOperation", "isHiddenTX", "width", "direction", "userId", "options", "isRightOperation"],
  components: {
    [ElDrawer.name]: ElDrawer,
    FormCustomer,
    [ElTabs.name]: ElTabs,
    [ElTabPane.name]: ElTabPane,
    [ElButton.name]: ElButton,
    [ElRow.name]: ElRow,
    [ElCol.name]: ElCol,
    [ElDatePicker.name]: ElDatePicker,
    [ElInput.name]: ElInput,
    [ElRadioGroup.name]: ElRadioGroup,
    [ElRadioButton.name]: ElRadioButton,
    [ElDialog.name]: ElDialog,
    [ElForm.name]: ElForm,
    [ElFormItem.name]: ElFormItem,
    [ElTimeline.name]: ElTimeline,
    [ElCard.name]: ElCard,
    [ElTimelineItem.name]: ElTimelineItem,
    CollectionDialog,
  },
  setup(props, context) {
    const activeName = ref("1");
    const followActiveName = ref("1");
    const formData = reactive({
      name: null,
      mobile: null,
      age: null,
      sex: null,
      occupationId: null,
      educationId: null,
      marriageStatus: null,
      contractStatus: null,
      contractTime: null,
      loanLimit: null,
      annualIncome: null,
      room: 0,
      accumulation: 0,
      social: 0,
      car: 0,
      enterprise: 0,
      policy: 0,
      business: 0,
      issued: 0,
      starStatus: null,
      status: null,
      followRemark: null,
      followId: null,
      remark: null,
    });
    /** 跟进记录评论 */
    const commentFormData = reactive({
      followRecordId: null,
      customerId: null,
      followUserid: null,
      commentUserid: null,
      content: null,
      parentId: 0
    });
    const { state } = useStore();
    const userInfo = computed(() => state.userInfo);

    const commentRules = reactive({
      content: [{ required: true, message: "请输入评论内容", trigger: "submit" }]
    })

    const rules = reactive({
      followRemark: [{ required: true, message: "请输入跟进备注", trigger: "submit" }],
    })

    const fastForms = ref(radioForms);

    const dictionaries = async () => {
      let list;
      if (props.options) {
        list = props.options;
      } else {
        const res = await getDictionaries();
        list = res.data;
      }

      forms[1][0].option = list.occupationList.map((item) => ({
        label: item.label,
        val: item.vlaue,
      }));
      forms[1][1].option = list.educationList.map((item) => ({
        label: item.label,
        val: item.vlaue,
      }));
      fastForms.value[4][0].option = list.statusList.map((item) => ({
        label: item.label,
        val: item.vlaue,
      }));
      fastForms.value[4][1].option = list.starList.map((item) => ({
        label: item.label,
        val: item.vlaue,
      }));
      fastForms.value[6][0].option = list.followList.map((item) => ({
        label: item.label,
        val: item.id,
      }));
    };
    const submitForm = () => {
      context.emit("submit");
    };

    const showDialog = computed(() => props.isShowDialog);
    const resetForm = () => { };
    const handleClose = () => {
      context.emit("close");
      reset(formData);
    };

    watch(
      () => [props.userId, props.isShowDialog],
      () => {
        if (props.isShowDialog) {
          dictionaries();
          getDetail(props.userId);
          getCommentList(props.userId)
        }
      }
    );
    watch(
      () => formData.followId,
      () => {
        if (formData.followId) {
          formData.followRemark = fastForms.value[6][0].option.find(
            (item) => item.val == formData.followId
          ).label;
        }
      }
    );
    const formRef = ref(null)
    const handleSubmit = async () => {
      if (!formData.followRemark) {
        ElMessage.error("请输入跟进备注");
        return;
      }
      // eslint-disable-next-line no-unused-vars
      const { followId, ...params } = formData;
      const res = await putCustomer({ ...params });
      if (res.code == 200) {
        ElMessage.success("编辑成功！");
        await getDetail(props.userId);
        // handleClose();
      } else {
        ElMessage.error(res.msg);
      }
    };

    const followList = ref([]);

    const getDetail = async (id) => {
      const res = await getCustomer(id);
      const {
        name,
        mobile,
        age,
        sex,
        occupationId,
        educationId,
        marriageStatus,
        contractStatus,
        contractTime,
        loanLimit,
        annualIncome,
        room,
        accumulation,
        social,
        car,
        enterprise,
        policy,
        business,
        issued,
        status,
        starStatus,
        followRemark,
        remark,
        followRecords,
        memorandums,
      } = res.data;
      formData.id = id;
      formData.name = name;
      formData.mobile = mobile;
      formData.age = age;
      formData.sex = sex;
      formData.occupationId = occupationId;
      formData.educationId = educationId;
      formData.marriageStatus = marriageStatus;
      formData.contractStatus = contractStatus;
      formData.contractTime = contractTime;
      formData.loanLimit = loanLimit;
      formData.annualIncome = annualIncome;
      formData.room = room;
      formData.accumulation = accumulation;
      formData.social = social;
      formData.car = car;
      formData.enterprise = enterprise;
      formData.policy = policy;
      formData.business = business;
      formData.issued = issued;
      formData.status = status;
      formData.starStatus = starStatus;
      formData.followRemark = followRemark;
      formData.remark = remark;
      followList.value = followRecords;
      reminds.value = memorandums;
    };

    /**提醒事项 */

    const reminds = ref([]);
    // const remindOptions = reactive([
    //   { label: "已提醒", val: 1 },
    //   { label: "未提醒", val: 0 },
    // ]);
    const addRemind = () => {
      reminds.value.push({
        remindTime: null,
        content: null,
      });
    };

    const handleSaveRemind = async (index, id) => {
      if (id) {
        const res = await putRemind({
          customerId: props.userId,
          ...reminds.value[index],
        });
        if (res.code == 200) {
          ElMessage.success("修改成功");
          getDetail(props.userId);
        }
      } else {
        const res = await postRemind({
          customerId: props.userId,
          ststus: 0,
          ...reminds.value[index],
        });
        if (res.code == 200) {
          ElMessage.success("保存成功");
          getDetail(props.userId);
        }
      }
    };

    const handleDeleteRemind = (index, id) => {
      ElMessageBox.confirm("确定要删除该提醒事项吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await deleteRemind(id);
          if (res.code == 200) {
            ElMessage({
              type: "success",
              message: "删除成功!",
            });
            reminds.value.splice(index, 1);
          } else {
            ElMessage({
              type: "error",
              message: res.msg,
            });
          }
        })
        .catch(() => { });
    };

    /**添加回款 */
    const isShowCollection = ref(false);
    const addCollection = () => {
      isShowCollection.value = true;
    };
    const collectionFormData = reactive({
      amount: null,
      collectionDate: null,
      serviceCharge: null,
    });
    const handleCollectionSubmit = async () => {
      const res = await postCollection({
        customerId: props.userId,
        ...collectionFormData,
      });
      if (res.code == 200) {
        ElMessage.success("保存成功");
        isShowCollection.value = false;
      } else {
        ElMessage.error(res.msg);
      }
    };
    const handleCollectionClose = () => {
      isShowCollection.value = false;
    };

    /**添加签单 */
    const isShowSign = ref(false);
    const addSign = () => {
      isShowSign.value = true;
    };
    const signFormData = reactive({
      amount: null,
      signDate: null,
      serviceCharge: null,
    });
    const handleSignSubmit = async () => {
      const res = await postSign({
        customerId: props.userId,
        ...signFormData,
      });
      if (res.code == 200) {
        ElMessage.success("保存成功");
        isShowSign.value = false;
      } else {
        ElMessage.error(res.msg);
      }
    };
    const handleSignClose = () => {
      isShowSign.value = false;
    };
    const handlePrevCustomer = () => context.emit("prev");
    const handleNextCustomer = () => context.emit("next");

    /**移入公海，重要客户 */
    const handleImport = async () => {
      const res = await movetoImportant([props.userId]);
      if (res.code == 200) {
        ElMessage.success("移入重要客户成功！");
        context.emit('list')
        context.emit('importList')
      } else {
        ElMessage.error(res.msg);
      }
    };

    const handlePublicPool = async () => {
      const res = await movetoPublicPool([props.userId]);
      if (res.code == 200) {
        ElMessage.success("移入公海成功！");
        context.emit('list')
      } else {
        ElMessage.error(res.msg);
      }
    };

    const handleRubbish = async () => {
      const res = await movetoRubbish([props.userId]);
      if (res.code == 200) {
        ElMessage.success("移入垃圾库成功！");
        context.emit('list')
      } else {
        ElMessage.error(res.msg);
      }
    };

    const handlePassage = async () => {
      const res = await movetoPassage([props.userId]);
      if (res.code == 200) {
        ElMessage.success("移入在途客户成功！");
        context.emit('list')
      } else {
        ElMessage.error(res.msg);
      }
    };

    // 初始选中跟进记录
    const currentSelectedRow = reactive({});

    const commentDialogTitle = ref('')
    /** 显示评论DiaLog */
    const isShowComment = ref(false)
    const showComment = (row) => {
      commentFormData.customerId = row.customerId;
      commentFormData.followUserid = row.userId;
      commentFormData.followRecordId = row.id;
      isShowComment.value = true;
      currentSelectedRow.value = row;
      commentDialogTitle.value = "跟进记录评论";
      // console.log(currentSelectedRow.value)
      //getCommentList(row.id)
    };

    /** 关闭评论 Dialog */
    const handleCommonColse = () => {
      commentFormRef.value.resetFields();
      isShowComment.value = false;
    };

    const commentFormRef = ref(null)

    /** 提交评论 */
    const submitCommentForm = async () => {
      commentFormRef.value.validate(async (valid) => {
        if (valid) {
          //console.log("submitCommentForm", commentFormData);
          const res = await addFollowRecordCommon(commentFormData);
          if (res.code == 200) {
            ElMessage.success("评论成功！");
            commentFormRef.value.resetFields();
            getCommentList(commentFormData.customerId)
            //reset(currentSelectedRow)
           // reset(currentReplyComment)
            isShowComment.value = false
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          return false;
        }
      });
    };

    /** 获取评论记录 */
    const commentListData = ref([]);
    const getCommentList = async (id) => {
      //console.log("getCommentList", id);
      const res = await followRecordCommentListByCustomer({ "customerId": id });
      if (res?.code == 200) {
        //console.log("getCommentList"+id,res.data);
        commentListData.value = res.data
        //console.log("filter",commentListData.value.filter(item=>item.followRecordId==1379612))
      }

    };

    /** 初始化当前回复的评论记录 */
    const currentReplyComment = reactive({});
    /** 回复 */
    const handleReply =async (row, followRow) => {
      
      const res=await checkCurrUserIsFollower({ "id": row.customerId })
      if(res?.code==200){
        if(!res.data){
          ElMessage.error("您不是跟进人不能回复评论！");
          return
        }
      }

      commentFormData.customerId = row.customerId;
      commentFormData.followUserid = row.followUserid;
      commentFormData.followRecordId = row.followRecordId;
      commentFormData.parentId = row.id

      isShowComment.value = true;
      currentSelectedRow.value = followRow;
      currentReplyComment.value = row;
      commentDialogTitle.value = "回复@" + row.commentUserName;
    };

    return {
      activeName,
      followActiveName,
      submitForm,
      formData,
      resetForm,
      showDialog,
      handleClose,
      forms,
      fastForms,
      handleSubmit,
      followList,
      reminds,
      addRemind,
      handleSaveRemind,
      //   remindOptions,
      handleDeleteRemind,
      addCollection,
      isShowCollection,
      collectionForms,
      collectionFormData,
      handleCollectionSubmit,
      handleCollectionClose,
      isShowSign,
      addSign,
      handleSignClose,
      handleSignSubmit,
      signFormData,
      signForms,
      handlePrevCustomer,
      handleNextCustomer,
      handleImport,
      handlePublicPool,
      handleRubbish,
      handlePassage,
      // 当前评论的跟进记录
      currentSelectedRow,
      // 显示评论标识
      isShowComment,
      // 显示评论方法
      showComment,
      // 评论表单数据
      commentFormData,
      // 评论表单验证规则
      commentRules,
      // 关闭评论Dialog
      handleCommonColse,
      // 提交表单
      submitCommentForm,
      // 评论表单标识
      commentFormRef,
      // 评论记录集
      commentListData,
      // 获取评论记录集方法
      getCommentList,
      // 回复
      handleReply,
      // 评论\回复Dialog标题
      commentDialogTitle,
      // 当前要回复的评论
      currentReplyComment,
      userInfo,
      rules,
      formRef
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/css/form";

.name-block {
  color: #406fff;
  cursor: pointer;
}

.el-drawer {
  .el-drawer__body {
    padding: 0 20px;
    overflow: scroll;
  }

  .el-drawer__header {
    padding: 10px;
    margin: 0;
  }
}

.el-card__body {
  padding: 0 10px;

  p {
    padding-top: 5px;
  }

  h4 {
    padding: 5px 0px;
  }
}

.el-timeline-item {
  padding-bottom: 10px;
}

.el-dialog__body {
  padding: 10px
}

.gjjl {
  display: block;
  width: 20px;
  background-color: #409eff;
  color: #FFF;
  padding: 5px 0px;
  line-height: 20px;
}

.left-block {
  position: absolute;
  top: 40px;
  left: 100px;
  z-index: 1000000;

  .label {
    margin-left: 10px;
    cursor: pointer;
  }
}

.right-block {
  position: absolute;
  top: 40px;
  right: 20px;
  z-index: 1000000;

  .label {
    margin-left: 10px;
    cursor: pointer;
  }
}

.content-customer {
  display: flex;
  background-color: #f5f5fa;
  padding-top: 4px;

  h2 {
    display: flex;
    justify-content: space-between;
    line-height: 36px;
    font-size: 16px;
    height: 36px;
    border-bottom: 1px solid #f5f5fa;
    margin-bottom: 20px;
    padding: 0 20px;
  }

  .left {
    flex: 1;
    background-color: white;
    padding-right: 20px;

    .submit-btn {
      height: 110px;
      position: absolute;
      top: 520px;
      left: 40px;
      width: 70px;
    }
  }

  .right {
    margin-left: 10px;
    width: 320px;
    background-color: white;
    position: relative;

    .remark-block {
      padding: 0 20px;
      height: 666px;
      overflow-y: scroll;

      li {
        margin-bottom: 10px;

        .avatar-header {
          display: flex;
        }

        .avatar {
          width: 40px;
          height: 40px;
          background-color: #406fff;
          border-radius: 50%;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          text-align: center;
        }

        .header {
          display: flex;
          margin-top: 10px;
        }

        .time {
          color: #999;
          margin-left: 10px;
        }

        .comment {
          color: #999;
          margin-left: 5px;
        }

        .title {
          font-size: 16px;
          font-weight: bold;
        }

        .line-left {
          min-width: 50px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .content {
          color: #666;
          margin-top: 10px;
          display: flex;
        }

        audio {
          width: 220px;
          height: 60px;
        }
      }
    }

    .submit-btn {
      position: absolute;
      bottom: 30px;
      left: 140px;
    }
  }

  .remind-block {
    display: flex;

    .el-date-editor {
      min-width: 180px;
      margin: 0 10px;
    }

    .el-radio-group {
      min-width: 140px;
      margin: 0 10px;
    }

    .content-block {
      margin-right: 10px;
    }
  }
}

.tabs-block {
  .el-tabs__item.is-active {
    color: #406fff;
  }

  .el-tabs__active-bar {
    background: #406fff;
  }

  .el-tabs__nav-wrap::after {
    border: none;
  }
}

.fast-write {
  .el-form-item:nth-child(12) {
    display: none;
  }
}

.fast-write {
  .el-row:nth-child(7) {
    .el-radio-group {
      margin-left: 120px;

      .el-radio {
        margin-bottom: 10px;
      }
    }
  }
}

.el-icon-phone {
  font-size: 18px;
  color: red;
}

.comments{
  margin-top:5px;
  .user {
    color: rgb(255, 6, 6);
    font-weight: bold;
  }

  .msg {
    color: #409eff;
    text-decoration: underline dotted #409eff
  }

  .cursor {
    cursor: pointer;
  }
  .reply{
    color:#999;
    font-weight: bold;
  }
}
</style>
