<template>
  <el-pagination
    background
    layout="total, sizes, prev, pager, next, jumper"
    :total="total"
    :page-sizes="[10, 50, 100, 200]"
    :page-size="10"
    :current-page="currentPage"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  >
  </el-pagination>
</template>

<script>
import { ElPagination } from "element-plus";
export default {
  name: "Pagniation",
  props: ["total"],
  components: {
    [ElPagination.name]: ElPagination,
  },
  setup(props, context) {
    const handleSizeChange = (size) => {
      context.emit("sizeChange", size);
    };

    const handleCurrentChange = (page) => {     
      context.emit("currentChange", page);
    };

    return {
      handleSizeChange,
      handleCurrentChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.el-pagination {
  margin: 20px 0;
  display: flex;
  justify-content: flex-end;
}
</style>