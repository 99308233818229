<template>
  <el-form
    class="form-block"
    :inline="true"
    label-suffix=":"
  >
    <template
      v-for="(filter, index) in filters"
      :key="index"
    >
      <el-row>
        <template
          v-for="(item, ind) in filter"
          :key="ind"
        >
          <el-col
            :span="item.column"
            :class="item.type == 'button' ? 'btn-block': ''"
          >
            <el-form-item
              :label="item.label"
              :label-width="item.labelWidth"
            >
              <el-input
                v-if="item.type === 'input'"
                v-model="formDatas[item.attr]"
                :placeholder="item.placeholder"
                size="small"
                :suffix-icon="item.suffixIcon"
              ></el-input>

              <el-select
                v-model="formDatas[item.attr]"
                v-if="item.type === 'select'"
                :placeholder="item.placeholder"
                size="small"
                @change="(id) => selectChange(id, item.attr)"
              >
                <template
                  v-for="option in item.option"
                  :key="option"
                >
                  <el-option
                    :label="option.label"
                    :value="option.val"
                  ></el-option>
                </template>
              </el-select>
              <el-date-picker
                v-if="item.type == 'dateRange'"
                v-model="formDatas[item.attr]"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="small"
                :value-format="item.valueFormat"
              />
              <el-checkbox-group
                size="small"
                v-if="item.type == 'checkBoxButton'"
                v-model="formDatas[item.attr]"
              >
                <el-checkbox
                  v-for="(op, ind) in item.option"
                  :label="op.val"
                  :key="ind"
                  border
                >{{op.label}}</el-checkbox>

              </el-checkbox-group>
              <el-radio-group
                size="small"
                v-if="item.type == 'radioGroup'"
                v-model="formDatas[item.attr]"
              >
                <el-radio
                  v-for="(op, ind) in item.option"
                  :label="op.val"
                  :key="ind"
                  border
                >{{op.label}}</el-radio>

              </el-radio-group>
              <div v-if="item.type=='button'">
                <el-button
                  @click="resetForm"
                  size="small"
                >重置</el-button>
                <el-button
                  type="primary"
                  @click="submitForm"
                  size="small"
                >查 询</el-button>
<!--                <el-button
                  v-if="item.isBtnText"
                  type="text"
                  size="small"
                  @click="handleUnflod"
                >{{isUnflod ? '收起': '展开'}}
                  <el-icon
                    class="el-icon-arrow-up"
                    v-if="isUnflod"
                  ></el-icon>
                  <el-icon
                    class="el-icon-arrow-down"
                    v-else
                  ></el-icon>
                </el-button>-->
              </div>
            </el-form-item>
          </el-col>
        </template>
      </el-row>
    </template>
  </el-form>
</template>

<script>
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElButton,
  ElSelect,
  ElOption,
  ElRow,
  ElCol,
  ElDatePicker,
  ElIcon,
  ElCheckboxGroup,
  ElCheckbox,
  ElRadioGroup,
  ElRadio,
} from "element-plus";
import { reactive, ref } from "vue";

export default {
  name: "Filter",
  props: ["filters", "formData"],
  components: {
    [ElFormItem.name]: ElFormItem,
    [ElInput.name]: ElInput,
    [ElForm.name]: ElForm,
    [ElButton.name]: ElButton,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElRow.name]: ElRow,
    [ElCol.name]: ElCol,
    [ElDatePicker.name]: ElDatePicker,
    [ElIcon.name]: ElIcon,
    [ElCheckboxGroup.name]: ElCheckboxGroup,
    [ElCheckbox.name]: ElCheckbox,
    [ElRadioGroup.name]: ElRadioGroup,
    [ElRadio.name]: ElRadio,
  },
  setup(props, context) {
    const name = ref("");
    const formDatas = reactive(props.formData);
    const submitForm = () => {
      context.emit("submit");
    };

    const resetForm = () => context.emit("reset");

    const isUnflod = ref(false);

    const handleUnflod = () => {
      isUnflod.value = !isUnflod.value;
      isUnflod.value ? context.emit("unflod") : context.emit("closeUp");
    };
    const selectChange = (id, attr) => {
      if (attr == "deptId") {
        context.emit("changeDept", id);
      }
    };

    return {
      submitForm,
      name,
      formDatas,
      resetForm,
      isUnflod,
      handleUnflod,
      selectChange,
    };
  },
};
</script>

<style lang="scss">
@import "../../assets/css/form";
.form-block {
  .el-checkbox.is-bordered.is-checked:after {
    display: block;
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    bottom: -1px;
    right: -1px;
    background: url("../../assets/images/right-icon.png") no-repeat;
    background-size: 100%;
  }
}
</style>