<template>
  <el-dialog
    :title="title"
    :width="width"
    v-model="showDialog"
    :before-close="handleColse"
  >
    <el-form
      :model="formData"
      :rules="rules"
      class="form-block"
      :inline="true"
      label-suffix=":"
      ref="formRef"
    >
      <template
        v-for="(filter, index) in forms"
        :key="index"
      >
        <el-row>
          <template
            v-for="(item, ind) in filter"
            :key="ind"
          >
            <el-col
              :span="item.column"
              :class="item.type == 'button' ? 'btn-block': ''"
            >
              <el-form-item
                :label="item.label"
                :label-width="item.labelWidth"
                :prop="item.attr"
              >
                <el-input
                  v-if="item.type === 'input'"
                  v-model="formDatas[item.attr]"
                  :placeholder="item.placeholder"
                  size="small"
                  :disabled="item.disabled"
                  :suffix-icon="item.suffixIcon"
                  :type="item.inputType"
                  :rows="item.rows"
                ></el-input>

                <el-select
                  v-model="formDatas[item.attr]"
                  v-if="item.type === 'select'"
                  :placeholder="item.placeholder"
                  size="small"
                  :disabled="item.disabled"
                  :multiple="item.multiple"
                >
                  <template
                    v-for="option in item.option"
                    :key="option"
                  >
                    <el-option
                      :label="option.label"
                      :value="option.val"
                    ></el-option>
                  </template>
                </el-select>
                <el-input
                  v-if="item.type === 'inputNumber'"
                  v-model="formDatas[item.attr]"
                  :placeholder="item.placeholder"
                  :disabled="item.disabled"
                  size="small"
                  type="number"
                ></el-input>
                <el-date-picker
                  v-if="item.type === 'date'"
                  v-model="formDatas[item.attr]"
                  type="date"
                  :placeholder="item.placeholder"
                  :value-format="item.valueFormat"
                  size="small"
                >
                </el-date-picker>
                <el-switch
                  v-model="formDatas[item.attr]"
                  v-if="item.type == 'switch'"
                  active-color="#406fff"
                  :active-value="1"
                  :inactive-value="0"
                />
                <el-radio-group
                  v-model="formDatas[item.attr]"
                  v-if="item.type == 'radio'"
                >
                  <template
                    v-for="option in item.option"
                    :key="option"
                  >
                    <el-radio :label="option.value">{{option.label}}</el-radio>
                  </template>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </template>
        </el-row>
      </template>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          @click="handleColse"
          size="small"
        >取消</el-button>
        <el-button
          type="primary"
          @click="submitForm"
          size="small"
        >保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElButton,
  ElSelect,
  ElOption,
  ElRow,
  ElCol,
  ElDialog,
  ElDatePicker,
  ElSwitch,
  ElRadioGroup,
  ElRadio,
  ElIcon,
} from "element-plus";
import { computed, reactive, ref } from "vue";

export default {
  name: "DialogFilter",
  props: ["forms", "formData", "title", "isShowDialog", "width", "rules"],
  components: {
    [ElFormItem.name]: ElFormItem,
    [ElInput.name]: ElInput,
    [ElForm.name]: ElForm,
    [ElButton.name]: ElButton,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElRow.name]: ElRow,
    [ElCol.name]: ElCol,
    [ElDialog.name]: ElDialog,
    [ElDatePicker.name]: ElDatePicker,
    [ElSwitch.name]: ElSwitch,
    [ElRadioGroup.name]: ElRadioGroup,
    [ElRadio.name]: ElRadio,
    [ElIcon.name]: ElIcon,
  },
  setup(props, context) {
    const formDatas = reactive(props.formData);
    const formRef = ref(null);
    const submitForm = () => {
      formRef.value.validate(async (valid) => {
        if (valid) {
          context.emit("submit");
        } else {
          return false;
        }
      });
    };

    const showDialog = computed(() => props.isShowDialog);

    const resetForm = () => {};
    const handleColse = () => context.emit("close");

    return {
      submitForm,
      formDatas,
      resetForm,
      showDialog,
      handleColse,
      formRef,
    };
  },
};
</script>

<style lang="scss">
@import "../../assets/css/form";
</style>