<template>
  <FilterForm
    :formData="filterData"
    :filters="filters"
    @submit="handleSubmit"
    @reset="handleReset"
    @changeDept="changeDept"
  />

  <CollectionTable
    :tableColumn="tableColumn"
    :tableData="tableData"
    @nameDrawer="handleNameDrawer"
    :page="pagination.page"
    :size="pagination.size"
    @delete="handleDelete"
  />
  <CollectionPagination
    :total="total"
    @currentChange="currentChange"
    @sizeChange="sizeChange"
  />

  <CustomerDetail
    width="1200px"
    :isShowDialog="isShowDrawer"
    @close="handleDrawerClose"
    direction="rtl"
    :userId="drawerId"
    @prev="handlePrevCustomer"
    @next="handleNextCustomer"
  />

</template>

<script>
import { ElButton, ElMessage, ElMessageBox } from "element-plus";
import { useStore } from "vuex";
import { computed, onMounted, reactive, ref } from "vue";
import FilterForm from "@/components/filter";
import CollectionTable from "@/components/table";
import CollectionPagination from "@/components/pagination";
import { filters, tableColumn } from "./index.js";
import { reset } from "@/until";
import CustomerDetail from "@/components/drawer-form/index.vue";
import { deleteCollection } from "@/api/method/customer";
import {getChannelList} from "@/api/method/channel";
import {getUserByDept} from "@/api/method/common";

export default {
  name: "Collection",
  props: ["options", "departments"],
  components: {
    FilterForm,
    CollectionTable,
    CollectionPagination,

    CustomerDetail,
    [ElButton.name]: ElButton,
  },

  setup() {
    const { dispatch, state } = useStore();
    const filterData = reactive({
      date: null,
      deptId:null,
      userId: null,
      source: null,
      name: null
    });

    onMounted(() => {
      getList();
      getDepartList()
      loadChannelList();
    });
    const myFilters = ref(filters);
    const deptList = ref([]);
    const getDepartList = async () => {
      await dispatch("departmentList");
      deptList.value = state.departmentList.map((item) => ({
        label: item.fullName,
        val: item.id,
      }));
      myFilters.value[0][0].option = deptList.value;
    };
    const userList = ref([]);
    const changeDept = async (id) => {
      const res = await getUserByDept(id);
      filterData.userId = null;
      userList.value = res.data.map((item) => ({
        label: item.nickname,
        val: item.id,
      }));
      myFilters.value[0][1].option = userList.value;
    };

    const channelList = ref([]);
    const loadChannelList = async () => {
      const res = await getChannelList();

      channelList.value = res.data.map((item) => ({
        label: item.name,
        val: item.id,
      }));

      myFilters.value[1][0].option = channelList.value;
    }

    const pagination = reactive({
      page: 1,
      size: 10,
    });
    const tableData = computed(
      () => (state.collectionList && state.collectionList.records) || []
    );
    const total = computed(
      () => state.collectionList && state.collectionList.total
    );
    const getList = () => {
      const { date ,...params} = filterData;

      const startTime = date && date[0];
      const endTime = date && date[1];
      dispatch("collectionList", {
        startTime,
        endTime,
        ...params,
        ...pagination,
      });
    };
    const currentChange = (page) => {
      pagination.page = page;
      getList();
    };
    const sizeChange = (size) => {
      pagination.size = size;
      getList();
    };

    const handleSubmit = () => getList();
    const handleReset = () => {
      reset(filterData);
      getList();
    };

    const isShowDrawer = ref(false);
    const handleDrawerClose = () => (isShowDrawer.value = false);
    const drawerId = ref(null);
    const handleNameDrawer = (row) => {
      isShowDrawer.value = true;
      drawerId.value = row.customerId;
    };

    /**客户详情-上一个和下一个 */
    const handleNextCustomer = () => {
      const index =
        state.collectionList.records.findIndex(
          (item) => item.customerId == drawerId.value
        ) + 1;
      if (index == state.collectionList.records.length) {
        ElMessage.error("已经是最后一个客户了！");
        return;
      }
      drawerId.value = state.collectionList.records[index].customerId;
    };
    const handlePrevCustomer = () => {
      const index =
        state.collectionList.records.findIndex(
          (item) => item.customerId == drawerId.value
        ) - 1;
      if (index < 0) {
        ElMessage.error("已经是第一个客户了！");
        return;
      }
      drawerId.value = state.collectionList.records[index].customerId;
    };

    const handleDelete = async (id) => {
      ElMessageBox.confirm("确定要删除该回款吗?", "提示", {
        closeOnClickModal: false,
        closeOnPressEscape: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await deleteCollection(id);
          if (res.code == 200) {
            ElMessage({
              type: "success",
              message: "删除成功!",
            });
            getList();
          } else {
            ElMessage({
              type: "error",
              message: res.msg,
            });
          }
        })
        .catch(() => {});
    };

    return {
      filters,
      filterData,
      handleSubmit,
      handleReset,
      tableData,
      tableColumn,
      total,
      isShowDrawer,
      handleDrawerClose,
      handleNameDrawer,
      drawerId,
      currentChange,
      sizeChange,
      pagination,
      changeDept,
      handleDelete,
      handlePrevCustomer,
      handleNextCustomer
    };
  },
};
</script>

<style>
</style>