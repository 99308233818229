<template>
  <el-form
    class="form-block"
    :inline="true"
    label-suffix=":"
    :rules="rules"
  >
    <template
      v-for="(form, index) in forms"
      :key="index"
    >
      <el-row>
        <template
          v-for="(item, ind) in form"
          :key="ind"
        >
          <el-col
            :span="item.column"
            :class="item.type == 'button' ? 'btn-block': ''"
          >
            <el-form-item
              :label="item.label"
              :label-width="item.labelWidth"
              :prop="item.attr"
            >
              <template
                #label
                v-if="item.labelCustom"
              >
                {{item.label}}
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="公司每日新资源分配数量"
                  placement="top-start"
                >
                  <span class="question">?</span>
                </el-tooltip>
                ：
              </template>
              <el-input
                v-if="item.type === 'input'"
                v-model="formDatas[item.attr]"
                :placeholder="item.placeholder"
                size="small"
                :disabled="item.disabled"
                :suffix-icon="item.suffixIcon"
                :type="item.inputType"
                :rows="item.rows"
              ></el-input>
              <i
                class="el-icon-phone"
                v-if="item.append && item.phone"
                @click="handleCall"
              ></i>

              <el-select
                v-model="formDatas[item.attr]"
                v-if="item.type === 'select'"
                :placeholder="item.placeholder"
                size="small"
              >
                <template
                  v-for="option in item.option"
                  :key="option"
                >
                  <el-option
                    :label="option.label"
                    :value="option.val"
                  ></el-option>
                </template>
              </el-select>
              <el-checkbox-group
                size="small"
                v-if="item.type == 'checkBoxButton'"
                v-model="formDatas[item.attr]"
              >
                <el-checkbox
                  v-for="(op, ind) in item.option"
                  :label="op.val"
                  :key="ind"
                  border
                >{{op.label}}</el-checkbox>

              </el-checkbox-group>
              <el-radio-group
                size="small"
                v-if="item.type == 'radioGroup'"
                v-model="formDatas[item.attr]"
              >
                <el-radio
                  v-for="(op, ind) in item.option"
                  :label="op.val"
                  :key="ind"
                  border
                >{{op.label}}</el-radio>

              </el-radio-group>
              <el-radio-group
                v-model="formDatas[item.attr]"
                v-if="item.type == 'radio'"
              >
                <template
                  v-for="option in item.option"
                  :key="option"
                >
                  <el-radio :label="option.value">{{option.label}}</el-radio>
                </template>
              </el-radio-group>
              <el-radio-group
                v-model="formDatas[item.attr]"
                v-if="item.type == 'radioButton'"
                size="small"
              >
                <template
                  v-for="option in item.option"
                  :key="option"
                >
                  <el-radio-button :label="option.value">{{option.label}}</el-radio-button>
                </template>
              </el-radio-group>
              <el-date-picker
                v-if="item.type === 'date'"
                v-model="formDatas[item.attr]"
                type="date"
                :placeholder="item.placeholder"
                :value-format="item.valueFormat"
                size="small"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </template>
      </el-row>
    </template>
  </el-form>
</template>

<script>
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElButton,
  ElSelect,
  ElOption,
  ElRow,
  ElCol,
  ElRadioGroup,
  ElRadio,
  ElDatePicker,
  ElRadioButton,
  ElTooltip,
  ElCheckboxGroup,
  ElCheckbox,
  ElCheckboxButton,
  ElIcon,
  ElMessage,
} from "element-plus";
import { reactive, ref } from "vue";
import { postCustomerCall } from "@/api/method/customer";

export default {
  name: "Form",
  props: ["forms", "formData", "rules"],
  components: {
    [ElFormItem.name]: ElFormItem,
    [ElInput.name]: ElInput,
    [ElForm.name]: ElForm,
    [ElButton.name]: ElButton,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElRow.name]: ElRow,
    [ElCol.name]: ElCol,
    [ElCheckboxGroup.name]: ElCheckboxGroup,
    [ElCheckbox.name]: ElCheckbox,
    [ElCheckboxButton.name]: ElCheckboxButton,
    [ElRadioGroup.name]: ElRadioGroup,
    [ElRadio.name]: ElRadio,
    [ElRadioButton.name]: ElRadioButton,
    [ElDatePicker.name]: ElDatePicker,
    [ElTooltip.name]: ElTooltip,
    [ElIcon.name]: ElIcon,
  },
  setup(props, context) {
    const name = ref("");
    const formDatas = reactive(props.formData);
    const submitForm = () => {
      context.emit("submit");
    };

    const resetForm = () => {};
    const handleCall = async () => {
      const res = await postCustomerCall(formDatas.id);
      if (res.code == 200) {
        ElMessage.success("成功");
      } else {
        ElMessage.error(res.msg);
      }
    };

    return {
      submitForm,
      name,
      formDatas,
      resetForm,
      handleCall,
    };
  },
};
</script>

<style lang="scss">
@import "../../assets/css/form";
.el-icon-phone {
  position: absolute;
  top: 10px;
  right: -20px;
  cursor: pointer;
}
</style>