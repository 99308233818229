<template>
  <el-table :data="tableData" stripe class="xinsheng-table" :row-key="(row) => row.id"
    @selection-change="handleSelectionChange" :default-expand-all="isExpand" :expand-row-keys="expandRow">
    <el-table-column type="selection" width="55" :fixed="true" v-if="selection" />
    <el-table-column type="index" v-if="numIndex" label="索引" :width="numWidth" />
    <template v-for="column in tableColumn" :key="column">
      <el-table-column v-if="!column.isCustomize" :width="column.width" :prop="column.prop" :label="column.label"
        :fixed="column.fixed">
        <template #default="{ row, column }">
          <span class="text text-overflow" v-if="row.followStatus == 1">{{ row[column.property] }}</span>
          <span class="text-overflow" v-else>{{ row[column.property] }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'no'" :width="column.width" :prop="column.prop"
        :label="column.label">
        <template #default="scope">
          <div v-if="scope.column.property == 'rankNo'" class="no-img">
            <img src="../../assets/images/one.png" alt v-if="page == 1 && scope.$index == 0" />
            <img src="../../assets/images/two.png" alt v-else-if="page == 1 && scope.$index == 1" />
            <img src="../../assets/images/three.png" alt v-else-if="page == 1 && scope.$index == 2" />
            <div class="img-bg" v-else>{{ (page - 1) * size + scope.$index + 1 }}</div>
          </div>
          <span v-else>{{ (page - 1) * size + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'sexNumber'" :width="column.width"
        :prop="column.prop" :label="column.label">
        <template #default="{ row, column }">
          <span v-if="row[column.property] == 0">
            <span class="text" v-if="row.followStatus == 1">男</span>
            <span v-else>男</span>
          </span>
          <span v-else-if="row[column.property] == 1">
            <span class="text" v-if="row.followStatus == 1">女</span>
            <span v-else>女</span>
          </span>
          <span v-else>
            <span class="text" v-if="row.followStatus == 1">未知</span>
            <span v-else>未知</span>
          </span>
        </template>
      </el-table-column>

      <el-table-column v-if="column.isCustomize && column.tableType === 'starStatus'" :width="column.width"
        :prop="column.prop" :label="column.label">
        <template #default="{ row, column }">
          <span class="text" v-if="row.followStatus == 1">
            <span v-if="row[column.property] == -2">-1星</span>
            <span v-if="row[column.property] == 0">0星</span>
            <span v-else-if="row[column.property] == 1">1星</span>
            <span v-else-if="row[column.property] == 2">2星</span>
            <span v-else-if="row[column.property] == 3">3星</span>
            <span v-else-if="row[column.property] == 4">4星</span>
            <span v-if="row[column.property] == 4.5">4星+</span>
            <span v-else-if="row[column.property] == 5">5星</span>
          </span>
          <span v-else>
            <span v-if="row[column.property] == -2">-1星</span>
            <span v-if="row[column.property] == 0">0星</span>
            <span v-else-if="row[column.property] == 1">1星</span>
            <span v-else-if="row[column.property] == 2">2星</span>
            <span v-else-if="row[column.property] == 3">3星</span>
            <span v-else-if="row[column.property] == 4">4星</span>
            <span v-if="row[column.property] == 4.5">4星+</span>
            <span v-else-if="row[column.property] == 5">5星</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'followUpStatus'" :width="column.width"
        :prop="column.prop" :label="column.label">
        <template #default="{ row, column }">
          <span class="text" v-if="row.followStatus == 1">
            <span class="text" v-if="row[column.property] == 0">未处理</span>
            <span v-else-if="row[column.property] == 2">上门未签约</span>
            <span v-else-if="row[column.property] == 3">已签约</span>
            <span v-else-if="row[column.property] == 6">银行已放款</span>
            <span v-else-if="row[column.property] == 130">未了解</span>
            <span v-else-if="row[column.property] == 131">资质不够</span>
            <span v-else-if="row[column.property] == 133">贷款资质不符</span>
            <span v-else-if="row[column.property] == 134">待签约</span>
            <span v-else-if="row[column.property] == 137">银行已拒绝</span>
            <span v-else-if="row[column.property] == 138">审核中</span>
            <span v-else-if="row[column.property] == 139">待跟进</span>
            <span v-else-if="row[column.property] == 141">捣乱申请</span>
            <span v-else-if="row[column.property] == 142">重复申请</span>
            <span v-else-if="row[column.property] == 143">外地申请</span>
            <span v-else-if="row[column.property] == 144">无效申请</span>
          </span>
          <span v-else>
            <span class="text" v-if="row[column.property] == 0">未处理</span>
            <span v-else-if="row[column.property] == 2">上门未签约</span>
            <span v-else-if="row[column.property] == 3">已签约</span>
            <span v-else-if="row[column.property] == 6">银行已放款</span>
            <span v-else-if="row[column.property] == 130">未了解</span>
            <span v-else-if="row[column.property] == 131">资质不够</span>
            <span v-else-if="row[column.property] == 133">贷款资质不符</span>
            <span v-else-if="row[column.property] == 134">待签约</span>
            <span v-else-if="row[column.property] == 137">银行已拒绝</span>
            <span v-else-if="row[column.property] == 138">审核中</span>
            <span v-else-if="row[column.property] == 139">待跟进</span>
            <span v-else-if="row[column.property] == 141">捣乱申请</span>
            <span v-else-if="row[column.property] == 142">重复申请</span>
            <span v-else-if="row[column.property] == 143">外地申请</span>
            <span v-else-if="row[column.property] == 144">无效申请</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'name'" :width="column.width"
        :prop="column.prop" :label="column.label">
        <template #default="{ row, column }">
          <span class="name-block" @click="handleNameDrawer(row)">{{
              row[column.property]
          }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'theadedOpeartion'" :width="column.width"
        :prop="column.prop" :label="column.label">
        <template #default="{ row }">
          <template v-for="(customer, index) in row.customers" :key="index">
            <span class="name-block splice-block" @click="handleNameDrawer(customer)">{{
                customer.costumerName
            }}</span>
          </template>
        </template>
      </el-table-column>

      <el-table-column v-if="column.isCustomize && column.tableType === 'nameNoPointer'" :width="column.width"
        :prop="column.prop" :label="column.label" :fixed="column.fixed">
        <template #default="{ row, column }">
         
            
          
          <span class="name-no-pointer" @click="handleNameDrawer(row)"><span class="text"
              v-if="row.followStatus == 0">{{ row[column.property] }}</span>
            <span v-else>
              {{ row[column.property] }}
            </span></span>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'nameNoComment'" :width="column.width"
        :prop="column.prop" :label="column.label" :fixed="column.fixed">
        <template #default="{ row, column }">
          
          <el-badge :value="row.unReadComment" class="item" v-if="row.unReadComment>0">
          <span class="name-no-pointer" @click="handleNameDrawer(row)"><span class="text"
              v-if="row.followStatus == 0">{{ row[column.property] }}</span>
            <span v-else>
              {{ row[column.property] }}
            </span></span></el-badge>
            <span v-else class="name-no-pointer" @click="handleNameDrawer(row)"><span class="text"
              v-if="row.followStatus == 0">{{ row[column.property] }}</span>
            <span v-else>
              {{ row[column.property] }}
            </span></span>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'visitNum'" :width="column.width"
        :prop="column.prop" :label="column.label">
        <template #default="{ row, column }">
          <span class="name-block" @click="handleVisitNum(row.userId)">{{
              row[column.property]
          }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'followRecords'" :width="column.width"
        :prop="column.prop" :label="column.label">
        <template #default="{ row, column }">
          <span class="text" v-if="row.followStatus == 1">
            <span @click="followRecords(row)" class="point-block" v-if="row[column.property].length">{{
                row[column.property][0].message
            }}</span>
          </span>
          <span v-else>
            <span @click="followRecords(row)" class="point-block" v-if="row[column.property].length">{{
                row[column.property][0].message
            }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'followRecordsLength'" :width="column.width"
        :prop="column.prop" :label="column.label">
        <template #default="{ row }">
          <span>{{ row.followRecords.length }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'source'" :width="column.width"
        :prop="column.prop" :label="column.label">
        <template #default="{ row, column }">
          <span v-if="row[column.property] == 0">导入</span>
          <span v-else-if="row[column.property] == 1">系统提供</span>
        </template>
      </el-table-column>

      <el-table-column v-if="column.isCustomize && column.tableType === 'customerSwitch'" :width="column.width"
        :prop="column.prop" :label="column.label">
        <template #default="{ row, column }">
          <div v-if="row[column.property] == 0" class="danger">
            <div class="circle-status danger-block"></div>
            <div>关闭</div>
          </div>
          <div class="success-tooltip" v-if="row[column.property] == 1">
            <div class="circle-status success-block"></div>
            <div>开启</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'userStatus'" :width="column.width"
        :prop="column.prop" :label="column.label">
        <template #default="{ row, column }">
          <div v-if="row[column.property] == 0" class="danger">
            <div class="circle-status danger-block"></div>
            <div>禁用</div>
          </div>
          <div class="success-tooltip" v-if="row[column.property] == 1">
            <div class="circle-status success-block"></div>
            <div>启用</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'dimissionStatus'" :width="column.width"
        :prop="column.prop" :label="column.label">
        <template #default="{ row, column }">
          <div v-if="row[column.property] == 0" class="danger">
            <div class="circle-status danger-block"></div>
            <div>离职</div>
          </div>
          <div class="success-tooltip" v-if="row[column.property] == 1">
            <div class="circle-status success-block"></div>
            <div>在职</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'departmentType'" :width="column.width"
        :prop="column.prop" :label="column.label">
        <template #default="{ row, column }">
          <div v-if="row[column.property] == 0" class="company-block">
            <div class="circle-status company-circle"></div>
            <div>公司</div>
          </div>
          <div v-if="row[column.property] == 1" class="dept-block">
            <div class="circle-status dept-circle"></div>
            <div>部门</div>
          </div>
          <div v-if="row[column.property] == 2" class="group-block">
            <div class="circle-status group-circle"></div>
            <div>小组</div>
          </div>
          <!-- <span v-if="row[column.property] == 0">公司</span> -->
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'dataType'" :width="column.width"
        :prop="column.prop" :label="column.label">
        <template #default="{ row, column }">
          <span v-if="row[column.property] == 0">个人</span>
          <span v-else-if="row[column.property] == 1">部门</span>
          <span v-else>未知</span>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'isVisit'" :width="column.width"
        :prop="column.prop" :label="column.label">
        <template #header="scope">
          <span>{{ scope.column.label }}</span>
          <el-tooltip class="item" effect="dark" content="该部门上门登记是否为客户，如果是客户，库里不存在会自动入库；否则只是上门登记" placement="top-start">
            <span class="question">?</span>
          </el-tooltip>
        </template>
        <template #default="{ row, column }">
          <span v-if="row[column.property] == 0">否</span>
          <span v-if="row[column.property] == 1">是</span>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'roleType'" :width="column.width"
        :prop="column.prop" :label="column.label">
        <template #default="{ row, column }">
          <span v-if="row[column.property] == 0">管理员</span>
          <span v-if="row[column.property] == 1">普通用户</span>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'dayNum'" :width="column.width"
        :prop="column.prop" :label="column.label">
        <template #default="{ row, column }">
          <el-input v-model="row[column.property]" @change="handleChange(row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'dayFormat'" :width="column.width"
        :prop="column.prop" :label="column.label">
        <template #default="{ row, column }">
          <el-input v-model="row[column.property]" @change="handleDayFormatChange(row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'failRecord'" :width="column.width"
        :prop="column.prop" :label="column.label">
        <template #default="{ row, column }">
          <span class="name-block" v-if="row[column.property]" @click="handleExcel(row[column.property])">下载失败记录</span>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'userOperate'" :width="column.width"
        :prop="column.prop" :label="column.label" :fixed="column.fixed">
        <template #default="{ row }">
          <el-button @click="goToView(row.id)" type="text">查看</el-button>
          <el-button @click="goToEdit(row.id)" type="text">编辑</el-button>
          <el-button v-if="row.initialization !== 1" @click="goToDelete(row)" type="text">{{ row.dimission == '0' ?
              '标记在职' : '标记离职'
          }}</el-button>
          <el-button @click="goToPassword(row.id)" type="text">重置密码</el-button>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'departmentOperate'" :width="column.width"
        :prop="column.prop" :label="column.label" :fixed="column.fixed">
        <template #default="{ row }">
          <el-button @click="goToView(row.id)" type="text">查看</el-button>
          <el-button @click="goToEdit(row.id, row.parentId)" type="text">编辑</el-button>
          <el-button v-if="row.initialization != 1" @click="goToDelete(row.id)" type="text">删除</el-button>
          <el-button @click="goToAdd(row.id)" type="text">新增子项</el-button>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'roleOperate'" :width="column.width"
        :prop="column.prop" :label="column.label" :fixed="column.fixed">
        <template #default="{ row, $index }">
          <el-button @click="goToView(row.id)" type="text">查看</el-button>
          <el-button @click="goToEdit(row.id, row.parentId)" type="text">编辑</el-button>
          <el-button v-if="$index !== 0" @click="goToDelete(row.id)" type="text">删除</el-button>
          <el-button @click="goToSetting(row.id)" type="text">菜单权限设置</el-button>
          <el-button @click="goToDataSetting(row.id)" type="text">数据权限设置</el-button>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'workReport'" :width="column.width"
        :prop="column.prop" :label="column.label" :fixed="column.fixed">
        <template #default="{ row }">
          <el-button @click="goToEdit(row.id)" type="text">编辑</el-button>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'signOperate'" :width="column.width"
        :prop="column.prop" :label="column.label" :fixed="column.fixed">
        <template #default="{ row }">
          <el-button v-if="row.hasDelete" @click="goToDelete(row.id)" type="text">删除</el-button>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'callRecordOperate'" :width="column.width"
        :prop="column.prop" :label="column.label" :fixed="column.fixed">
        <template #default="{ row }">

          <audio v-if="row.voicePath !== null" :src="row.voicePath" controls="true"></audio>
          <!-- <el-button
            v-if="row.voicePath !== null"
            @click="downloadVoice(row.voicePath)"
            type="text"
            >下载通话录音</el-button> -->
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'probationType'" :width="column.width"
        :prop="column.prop" :label="column.label">
        <template #default="{ row, column }">
          <span v-if="row[column.property] == 1">试用期</span>
          <span v-if="row[column.property] == 2">正式</span>
        </template>
      </el-table-column>
      <el-table-column v-if="column.isCustomize && column.tableType === 'calledType'" :width="column.width"
        :prop="column.prop" :label="column.label">
        <template #default="{ row, column }">
          <span v-if="row[column.property] == 1">呼入</span>
          <span v-if="row[column.property] == 2">呼出</span>
          <span v-if="row[column.property] == 3">拒接</span>
        </template>
      </el-table-column>
    </template>
  </el-table>
</template>

<script>
import {
  ElTable,
  ElTableColumn,
  ElButton,
  ElMessageBox,
  ElMessage,
  ElSwitch,
  ElLoading,
  ElTooltip,
  ElInput,
  ElBadge,
} from "element-plus";
// import { useRouter } from "vue-router";
import { ref } from "vue";

export default {
  name: "UserTable",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElButton.name]: ElButton,
    [ElMessageBox.name]: ElMessageBox,
    [ElMessage.name]: ElMessage,
    [ElSwitch.name]: ElSwitch,
    [ElLoading.name]: ElLoading,
    [ElTooltip.name]: ElTooltip,
    [ElInput.name]: ElInput,
    [ElBadge.name]:ElBadge
  },
  props: [
    "tableData",
    "tableColumn",
    "isLoading",
    "tableWidth",
    "selection",
    "numIndex",
    "numWidth",
    "isExpand",
    "expandRow",
    "page",
    "size",
  ],
  setup(props, context) {
    // const router = useRouter();
    // let loadingInstance;
    // watch(
    //   () => props.isLoading,
    //   () => {
    //     if (props.isLoading) {
    //       loadingInstance = ElLoading.service({
    //         target: document.querySelector(".el-table__body"),
    //         text: "正在加载数据...",
    //         spinner: "el-icon-loading",
    //       });
    //     } else {
    //       loadingInstance && loadingInstance.close();
    //     }
    //   }
    // );
    const emptyText = ref(null);

    const handleSelectionChange = (val) => {
      context.emit("selection", val);
    };

    const goToEdit = (id, str) => {
      context.emit("edit", id, str);
    };
    const goToPassword = (id) => context.emit("resetPassword", id);

    const goToDelete = (row) => context.emit("delete", row);
    const goToView = (id) => context.emit("view", id);
    const goToAdd = (id) => context.emit("add", id);
    const goToSetting = (id) => context.emit("setting", id);
    const goToDataSetting = (id) => context.emit("dataSetting", id);
    const handleNameDrawer = (row) => context.emit("nameDrawer", row);
    const followRecords = (row) => context.emit("followRecords", row);
    const handleExcel = (url) => (window.location.href = url);
    const handleVisitNum = (id) => context.emit("visitNum", id);
    const handleChange = (row) => context.emit("changeInput", row);
    const handleDayFormatChange = (row) => context.emit("changeDayInput", row);
    const downloadVoice = (path) => context.emit("downloadVoice", path);

    return {
      emptyText,
      handleSelectionChange,
      goToEdit,
      goToDelete,
      goToView,
      goToAdd,
      goToSetting,
      goToDataSetting,
      handleNameDrawer,
      followRecords,
      handleExcel,
      handleVisitNum,
      goToPassword,
      handleChange,
      handleDayFormatChange,
      downloadVoice,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variable.scss";
@import "./index.scss";

.text-overflow {
  overflow: hidden;

  text-overflow: ellipsis;

  display: -webkit-box;

  -webkit-line-clamp: 3;

  overflow: hidden;
  -webkit-box-orient: vertical;
}
.el-badge__content.is-fixed{
  top:10px;
  right: 0px;
}
</style>
